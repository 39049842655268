import { HttpResponse, HttpStatus, BASE_URL, AUTH_DEBUG } from "../default";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

const patchUser = async (user, formData, token) => {
    const url = `${BASE_URL}/users/${user}/`
    var errorMessage;
    try {
        const options = {
            method: 'PUT',
            credentials: 'include',
            headers: {
                jwt: token,
            },
            body: formData,
        }

        const response = await fetch(url, options);
        if (response.ok) {
            const data = await response.json();
            AUTH_DEBUG && console.log("UserAPI::patchUser(): ", data.token);
            return new HttpResponse(HttpStatus.OK, data);
        } else {
            errorMessage = await response.json();
            throw new Error("Error on UserAPI()")
        }
    } catch (error) {
        console.warn(error)
        return new HttpResponse(HttpStatus.ERROR, errorMessage);
    }
}

const PostUserAddress = async (user, formData, token) => {
    const url = `${BASE_URL}/users/address/${user}/`
    var errorMessage;
    try {
        const options = {
            method: 'POST',
            credentials: 'include',
            headers: {
                jwt: token,
            },
            body: formData,
        }

        const response = await fetch(url, options);
        if (response.ok) {
            const data = await response.json();
            AUTH_DEBUG && console.log("UserAPI::PostUserAddress(): ", data.token);
            return new HttpResponse(HttpStatus.OK, data);
        } else {
            errorMessage = await response.json();
            throw new Error("Error on UserAPI()")
        }
    } catch (error) {
        console.warn(error)
        return new HttpResponse(HttpStatus.ERROR, errorMessage);
    }
}

const getClientSchedules = async (user_id, token, page) => {
    const url = `${BASE_URL}/partner/client/getSchedulesList/${user_id}/?page=${page}`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("UserAPI::getClientSchedules(): ", data);
        return data;
    } else {
        throw new Error("Error on getClientSchedules()");
    }
};

export function useClientSchedulesData(user_id, token, page) {
    const query = useQuery({
        queryKey: [ 'ClientSchedules', user_id, page ],
        queryFn: () => getClientSchedules(user_id, token, page),
        enabled: !!user_id,
        retry: 0,
    });

    return {
        ...query,
        ClientData: query.data,
    };
}

const getClientOneSchedule = async (user_id, token, schedule_id) => {
    const url = `${BASE_URL}/partner/client/getSchedulesDetails/${user_id}/${schedule_id}/`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("UserAPI::getClientOneSchedule(): ", data);
        return data;
    } else {
        throw new Error("Error on getClientOneSchedule()");
    }
};

export function useClientOneScheduleData(user_id, token, schedule_id) {
    const query = useQuery({
        queryKey: [ 'ClientOneSchedule', user_id, schedule_id ],
        queryFn: () => getClientOneSchedule(user_id, token, schedule_id),
        enabled: !!user_id,
        retry: 0,
    });

    return {
        ...query,
        ClientData: query.data,
    };
}

const getClientRequests = async ( token ) => {
    const url = `${BASE_URL}/payment/clientOrders/`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("UserAPI::getClientSchedules(): ", data);
        return data;
    } else {
        throw new Error("Error on getClientSchedules()");
    }
};

export function useClientRequestsData( token, clientEmail ) {
    const query = useQuery({
        queryKey: [ 'ClientRequests' ],
        queryFn: () => getClientRequests( token, clientEmail ),
        enabled: !!token && !!clientEmail,
        retry: 0,
    });

    return {
        ...query,
        ClientData: query.data,
    };
}

const getOneClientRequestsDetails = async ( token, requestSlug ) => {
    const url = `${BASE_URL}/payment/clientOrders/${requestSlug}/`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("UserAPI::getClientSchedules(): ", data);
        return data;
    } else {
        throw new Error("Error on getClientSchedules()");
    }
};

export function useOneClientRequestsDetailsData( token, requestSlug ) {
    const query = useQuery({
        queryKey: [ 'OneClientRequests' ],
        queryFn: () => getOneClientRequestsDetails( token, requestSlug ),
        enabled: !!token && !!requestSlug,
        retry: 0,
    });

    return {
        ...query,
        ClientRequestData: query.data,
    };
}

const getClientPartnerPerfil = async (partner_id) => {
    const url = `${BASE_URL}/partner/client/partnerPerfil/${partner_id}/`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("UserAPI::getClientPartnerPerfil(): ", data);
        return data;
    } else {
        throw new Error("Error on getClientPartnerPerfil()");
    }
};

export function useClientPartnerPerfilData(partner_id) {
    const query = useQuery({
        queryKey: [ 'ClientPartnerPerfil', partner_id ],
        queryFn: () => getClientPartnerPerfil(partner_id),
        enabled: !!partner_id,
        retry: 0,
    });

    return {
        ...query,
        PerfilData: query.data,
    };
}

const getStatesAndCities = async (token, filterCity) => {
    const url = filterCity 
        ? `${BASE_URL}/addressCities/?city=${encodeURIComponent(filterCity)}`
        : `${BASE_URL}/addressCities/`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("UserAPI::getStatesAndCities(): ", data);
        return data;
    } else {
        throw new Error("Error on getStatesAndCities()");
    }
};

export function useGetStatesAndCitiesData(token, filterCity) {
    const query = useQuery({
        queryKey: [ 'StatesAndCitiesClient', filterCity ],
        queryFn: () => getStatesAndCities( token, filterCity),
        enabled: !!token,
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 5,
        retry: 1,
    });

    return {
        ...query,
        CitiesAndStatesData: query.data,
    };
}

const getPublicStatesAndCities = async (filterCity) => {
    const url = filterCity 
        ? `${BASE_URL}/publicStates/?city=${encodeURIComponent(filterCity)}`
        : `${BASE_URL}/publicStates/`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("UserAPI::getStatesAndCities(): ", data);
        return data;
    } else {
        throw new Error("Error on getStatesAndCities()");
    }
};

export function useGetPublicStatesAndCitiesData(filterCity) {
    const query = useQuery({
        queryKey: [ 'StatesAndCitiesHome', filterCity ],
        queryFn: () => getPublicStatesAndCities(filterCity),
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 5,
        retry: 0,
    });

    return {
        ...query,
        StatesAndCitiesData: query.data,
    };
}

const patchOneRequestPaymentClient = async ({ requestSlug, token }) => {
    const url = `${BASE_URL}/payment/clientOrders/${requestSlug}/`;

    const options = {
        method: 'PATCH',
        credentials: 'include',
        headers: {
            jwt: token,
        },
    };

    const response = await fetch(url, options);
    const responseData = await response.json();

    if (!response.ok) {
        const errorDetail = responseData?.cpf?.[0] || responseData.detail || 'Erro na requisição';
        throw new Error(errorDetail);
    }

    return responseData;
};

export function usePatchOneRequestPaymentClient(requestSlug, token) {
    const queryClient = useQueryClient();

    const mutate = useMutation({
        mutationFn: patchOneRequestPaymentClient,
        mutationKey: { requestSlug },
        enabled: [!!requestSlug && !!token],
        onSuccess: () => {
            queryClient.invalidateQueries([ 'OneClientRequests', requestSlug ]);
        },
    });

    return mutate;
}

export const UserAPI = {
    patchUser,
    PostUserAddress,
}