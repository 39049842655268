import React from 'react';
import { Container, Row, Col, Button, Image, Card } from 'react-bootstrap';
import styles from "./style.module.scss";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useAuthContext } from '../../contexts/authContext';
import NoImage from "../../assets/no-image.jpg";
import { useNavigate, useParams } from 'react-router-dom';
import { useClientOneScheduleData } from '../../api/userAPI/userClient';
import dayjs from 'dayjs';

export default function ClientScheduleDetailsPage() {
    const { schedule_id } = useParams();
    const { token, user } = useAuthContext();
    const navigate = useNavigate();

    const { ClientData, isError: isErrorClient, isLoading: isLoadingClient } = useClientOneScheduleData(user.id, token, schedule_id);

    if (isLoadingClient) {
        return <p>Carregando detalhes do agendamento...</p>;
    }

    if (isErrorClient || !ClientData) {
        return <p>Erro ao carregar os dados do agendamento. Tente novamente mais tarde.</p>;
    }

    const { partner, employee, service, start_time, end_time, observations, status } = ClientData;

    return (
        <HelmetProvider>
            <section id={styles.ClientScheduleDetailsPage}>
                <Helmet>
                    <title>Agendamento - GoinGuru</title>
                    <meta name="description" content="GoinGuru" />
                </Helmet>
                <Container>
                    <Row className={styles.rowTitle}>
                        <p>Detalhes do Agendamento</p>
                    </Row>
                    <Row className={styles.rowButton}>
                        <Col className={styles.colButtonCancelar}>
                            <Button className={styles.buttonCancelar} onClick={() => navigate(-1)}>Voltar</Button>
                        </Col>
                        <Col>
                            <Button className={styles.button}>Preciso de Atendimento</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Card className={styles.cardClientSchedule}>
                            <Row className={styles.rowClientSchedule}>
                                <Col xs={3} sm={4} md={3} lg={2} xl={2} className={styles.colPartnerSchedule}>
                                    <Image
                                        src={service?.photo || NoImage}
                                        className={styles.image}
                                        alt="Serviço"
                                    />
                                </Col>
                                <Col xs={9} sm={8} md={7} lg={8} xl={8} className={styles.colServiceSchedule}>
                                    <p><strong>Serviço:</strong> {service?.name || "Não informado"}</p>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                            <p className={styles.text}>
                                                <strong>Data:</strong> {dayjs(start_time).format("DD/MM/YYYY")} das {dayjs(start_time).format("HH:mm")} às {dayjs(end_time).format("HH:mm")}
                                            </p>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                                            <p className={styles.subText}>
                                                <strong>Preço:</strong> R$ {service?.price || "0,00"}
                                            </p>
                                        </Col>
                                    </Row>
                                    <p><strong>Observações:</strong> {observations || "Nenhuma observação"}</p>
                                    <p className={styles.subText}>
                                        <strong>Tipo do serviço: </strong> 
                                        {service?.is_home_service ? "No local" : service?.is_online_service ? "Online" : "Presencial"}
                                    </p>
                                </Col>
                                <Col xs={12} sm={12} md={2} lg={2} xl={2} className={styles.colEmployeeSchedule}>
                                    <p><strong>Colaborador:</strong> {employee?.name || "Não informado"}</p>
                                    <Image
                                        src={employee?.photo || NoImage}
                                        className={styles.image}
                                        alt="Colaborador"
                                    />
                                </Col>
                            </Row>
                            <Row className={styles.rowPartnerSchedule}>
                                <Col xs={3} sm={3} md={2} lg={2} xl={2} className={styles.colPartnerSchedule}>
                                    <Image
                                        src={partner?.photo || NoImage}
                                        className={styles.image}
                                        alt="Parceiro"
                                    />
                                </Col>
                                <Col xs={9} sm={9} md={10} lg={10} xl={10}>
                                    <p><strong>Empresa:</strong> {partner?.name || "Não informado"}</p>
                                    <Row>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <p><strong>CNPJ:</strong> {partner?.cnpj || "Não informado"}</p>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <p><strong>WhatsApp:</strong> {partner?.whatsapp || "Não informado"}</p>
                                        </Col>
                                    </Row>
                                    <p><strong>Endereço:</strong> 
                                        {partner?.a_street_name}, {partner?.a_number} {partner?.a_complement || ""}, {partner?.a_neighborhood}, {partner?.a_city} - {partner?.a_state}, CEP {partner?.a_postal_code}
                                    </p>
                                </Col>
                            </Row>
                        </Card>
                    </Row>
                </Container>
            </section>
        </HelmetProvider>
    );
}
