import React, { useState } from 'react';
import { Container, Row, Col, Image, Spinner, Alert, Card, Badge } from 'react-bootstrap';
import styles from "./style.module.scss"
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useAuthContext } from '../../contexts/authContext';
import { useClientSchedulesData } from '../../api/userAPI/userClient';
import Pagination from '../../components/pagination';
import NoImage from "../../assets/no-image.jpg";
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

export default function ClientSchedulesPage() {
    const { token, user } = useAuthContext();
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const itemsPerPage = 10;

  const [status, setStatus] = useState(true);

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const { ClientData, isError: isErrorClient, isLoading: isLoadingClient } = useClientSchedulesData(user.id, token, page);

    return (
        <HelmetProvider>
            <section id={styles.ClientSchedulesPage}>
                <Helmet>
                    <title>Meus Agendamentos - GoinGuru</title>
                    <meta name="description" content="GoinGuru" />
                </Helmet>
                <Container>
                    <Row className={styles.rowTitle}>
                        <p>Meus Agendamentos</p>
                    </Row>
                    {isLoadingClient && (
                        <Col className="text-center">
                            <Spinner animation="border" role="status">
                            </Spinner>
                        </Col>
                    )}
                    {isErrorClient && (
                        <Col>
                            <Alert variant="danger">
                                Erro ao carregar os dados. Por favor, tente novamente mais tarde.
                            </Alert>
                        </Col>
                    )}
                    <Row className={styles.rowSchedule}>
                        {!isLoadingClient && !isErrorClient && ClientData && ClientData.results.length > 0 && ClientData.results.map( scheduleClient => (
                            <Card key={scheduleClient.id} className={styles.cardClientSchedule} onClick={() => navigate(`/agendamento/${scheduleClient.id}`)}>
                                <Row className={styles.rowClientSchedule}>
                                    <Col xs={3} sm={4} md={3} lg={2} xl={2} className={styles.colPartnerSchedule}>
                                        <Image
                                            src={scheduleClient.service.photo || NoImage}
                                            className={styles.image}
                                        />
                                    </Col>
                                    <Col xs={9} sm={8} md={9} lg={10} xl={10} className={styles.colServiceSchedule}>
                                        <p><strong>Serviço:</strong> {scheduleClient.service.name}</p>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                                <p className={styles.text}>
                                                    <strong>Data:</strong> {scheduleClient.start_time ? format(new Date(scheduleClient.start_time), 'dd/MM/yyyy - HH:mm ') : ''}
                                                    às {scheduleClient.end_time ? format(new Date(scheduleClient.end_time), ' HH:mm') : ''}
                                                </p>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                                                <p className={styles.subText}>
                                                    <strong>Preço:</strong> {scheduleClient.service.is_custom_quote ? 'A Combinar' : (scheduleClient.service.price ? formatPrice(scheduleClient.service.price) : '-')}
                                                </p>
                                            </Col>
                                        </Row>
                                        <p><strong>Observações:</strong> {scheduleClient.observations}</p>
                                        <p className={styles.subText}>
                                            <strong>Tipo do serviço: </strong> 
                                            {scheduleClient.service.is_home_service
                                                ? 'Domicílio'
                                                : scheduleClient.service.is_online_service
                                                ? 'Online'
                                                : 'No Local'
                                            }
                                        </p>
                                    </Col>
                                    {/* <Col xs={12} sm={12} md={2} lg={2} xl={2} className={styles.colEmployeeSchedule}>
                                        <p><strong>status:</strong></p>
                                        <Badge
                                            bg={status ? "success" : "danger"}
                                            className={styles.badge}
                                        >
                                            {status ? "Pago" : "Em andamento"}
                                        </Badge>
                                    </Col> */}
                                </Row>
                            </Card>
                        ))}
                    </Row>
                    <Row>
                    {ClientData && ClientData.results.length > 0 && (
                        <Pagination
                            totalPages={Math.ceil(ClientData.count / itemsPerPage)}
                            onPageChange={handlePageChange}
                            currentPage={page}
                        />
                    )}
                    {ClientData && ClientData.results.length === 0 && (
                        <Alert>
                            Você não possui agendamentos no momento!
                        </Alert>
                    )}
                </Row>
                </Container>
            </section>
        </HelmetProvider>
    );
}

const formatPrice = (value) => {
    if (!value) return '-';
    const number = parseFloat(value);
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(number);
};